import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Heading from "../../components/Layout/Heading"
import Content from "../../components/Layout/Content"
import ContactForm from "../../components/Forms/Contact"

const Contact = ({ location, pageContext }) => {
  const { page } = pageContext

  return (
    <Layout location={location}>
      <SEO title={page.title} description={page.description} />
      <div className="row">
        <div className="col-md-8">
          <Heading title={page.title} />
          <Content content={page.content} />
        </div>
        <div className="col-md-4">
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
