import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"

import useForm from "../Hooks/useForm"
import validate from "../Validation/Contact"

import Message from "../../Message"
import Icon from "../../Icon"
import Feedback from "../Feedback"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const ContactForm = () => {
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  } = useForm(submit, validate)

  function submit() {
    setMessage({})
    setIsSubmitting(true)
    Axios.post(`${API}/forms/contact`, values)
      .then(() => {
        setIsSubmitting(false)
        setMessage({
          type: "success",
          text: "Your enquiry has been sent",
        })
        resetReCaptcha(recaptchaInstance)
      })
      .catch((error) => {
        setIsSubmitting(false)
        setMessage({
          type: "danger",
          text: "Your enquiry failed to send",
        })
        resetReCaptcha(recaptchaInstance)
      })
  }

  console.log({ values })

  return (
    <>
      <h1>General Enquiry:</h1>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="businessName">Business Name:</label>
              <input
                onChange={handleChange}
                name="businessName"
                className="form-control"
                id="businessName"
                aria-label="Business Name"
              />
              <Feedback message={errors.businessName} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                onChange={handleChange}
                name="name"
                className="form-control"
                id="name"
                aria-label="Your Name"
              />
              <Feedback message={errors.name} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                onChange={handleChange}
                name="email"
                className="form-control"
                id="email"
                aria-label="Your Email"
              />
              <Feedback message={errors.email} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                onChange={handleChange}
                name="phone"
                className="form-control"
                id="phone"
                aria-label="Your Phone Number"
              />
              <Feedback message={errors.phone} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="form-check">Please send me details of:</label>
              <div className="form-check">
                <input
                  onChange={handleCheckboxChange}
                  name="theBundyBook"
                  className="form-check-input"
                  type="checkbox"
                  aria-label="The Bundy Book"
                />
                <label htmlFor="theBundyBook" className="form-check-label">
                  The Bundy Book
                </label>
                <br />
                <input
                  onChange={handleCheckboxChange}
                  name="theBundyLocal"
                  className="form-check-input"
                  type="checkbox"
                  aria-label="The Bundy Local"
                />
                <label htmlFor="theBundyLocal" className="form-check-label">
                  The Bundy Local
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="enquiry">Enquiry:</label>
              <textarea
                onChange={handleChange}
                name="enquiry"
                className="form-control"
                rows="5"
                id="enquiry"
                aria-label="Your Message"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <Recaptcha
            ref={(e) => (recaptchaInstance = e)}
            sitekey={recaptchaKey}
            render="explicit"
            onloadCallback={() => {}}
            verifyCallback={(response) => {
              handleReCaptcha(response)
            }}
          />
          <Feedback message={errors.reCaptcha} />
        </div>
        <Message type={message.type} message={message.text} />
        <div className="form-group">
          {isSubmitting ? (
            <Button type="submit" disabled bsPrefix="cln">
              Sending <Icon prefix="fas" name="spinner" spin={true} />
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit} bsPrefix="cln">
              Send
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default ContactForm
