export default function validateContact(values) {
  let errors = {}

  if (!values.businessName) {
    errors.businessName = "Please enter your Business Name"
  }

  if (!values.name) {
    errors.name = "Please enter your Name"
  }

  if (!values.email) {
    errors.email = "Please enter your Email"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.phone) {
    errors.phone = "Please enter your Phone Number"
  }

  if (!values.reCaptcha) {
    errors.reCaptcha = "Please verify you are a human"
  }

  return errors
}
